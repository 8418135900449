import React from 'react'
import { connect } from 'react-redux'
import { setActiveStep, setSelectedPlantType } from '../actions'

class SelectType extends React.Component {
  nextStep(selectedPlantType, e) {
    e.preventDefault()
    this.props.setActiveStep(this.props.currentStep + 1)
    this.props.setSelectedPlantType(selectedPlantType)
    this.props.nextStep()
  }

  render() {
    return (
      <>
        <form id="stepsForm">
          <label onClick={this.nextStep.bind(this, 0)}>
            <span className="labelTxt">Rośliny rolnicze</span>
          </label>
          <label onClick={this.nextStep.bind(this, 1)}>
            <span className="labelTxt">Rośliny sadownicze</span>
          </label>
          <label onClick={this.nextStep.bind(this, 2)}>
            <span className="labelTxt">Rośliny warzywne</span>
          </label>
          <label onClick={this.nextStep.bind(this, 3)}>
            <span className="labelTxt">Pozostałe rośliny</span>
          </label>
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  buttons: state.program.buttons
})

const mapDispatchToProps = (dispatch) => ({
  setActiveStep: (stepNumber) => dispatch(setActiveStep(stepNumber)),
  setSelectedPlantType: (selectedPlantType) =>
    dispatch(setSelectedPlantType(selectedPlantType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectType)
