import React from 'react'
import { connect } from 'react-redux'
import { restartProgram } from '../actions/index'

class SuccessStep extends React.Component {
  startOver = (e) => {
    e.preventDefault()
    this.props.restartProgram()
    this.props.goToStep(1)
  }

  render() {
    return (
      <>
        <form id="stepsForm">
          <div>Gratulacje!</div>
          <div>
            Plik zostanie zapisany lokalnie na Państwa komputerze lub telefonie.{' '}
            <br />
            Prosimy o sprawdzenie swojego folderu Pobierania lokalnie na
            urządzeniu.
          </div>
        </form>
        <div className="btnsHolder">
          <div className="btnsHolder">
            <button
              className="btnNext"
              onClick={this.startOver.bind(this)}
              title="Następny program"
            >
              Generuje następny program
            </button>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  restartProgram: () => {
    dispatch(restartProgram())
  }
})

export default connect(null, mapDispatchToProps)(SuccessStep)
