const Plants = {
  type: [
    {
      category: 'rolnicze',
      items: [
        {
          name: 'pszenica',
          list: [
            { name: 'jara', id: 89 },
            { name: 'ozima', id: 91 }
          ],
          icon: 'iconPszenica',
          box: true
        },
        {
          name: 'jęczmień',
          list: [
            { name: 'jary', id: 58 },
            { name: 'ozimy', id: 59 }
          ],
          icon: 'iconJeczmien',
          box: true
        },
        {
          name: 'rzepak',
          list: [
            { name: 'jary', id: 98 },
            { name: 'ozimy', id: 99 }
          ],
          icon: 'iconRzepak',
          box: true
        },
        { name: 'kukurydza', id: 72, icon: 'iconKukurydza' },
        {
          name: 'burak cukrowy',
          list: [
            { name: 'burak cukrowy', id: 34 },
            { name: 'Burak cukrowy plantacje nasienne', id: 35 }
          ],
          id: 34,
          icon: 'iconBurak'
        },
        { name: 'ziemniak', id: 110, icon: 'iconZiemniaki' },
        {
          name: 'pozostałe',
          box: true,
          list: [
            {
              name: 'Gorczyca',
              list: [
                { name: 'Gorczyca', id: 44 },
                { name: 'Gorczyca biała', id: 45 },
                { name: 'Gorczyca czarna', id: 46 },
                { name: 'Gorczyca sarepska', id: 47 }
              ],
              id: 44
            },
            {
              name: 'Groch',
              list: [
                { name: 'Groch', id: 156 },
                { name: 'Groch jadalny na suche nasiona', id: 48 },
                { name: 'Groch jadalny zielony', id: 49 },
                { name: 'Groch na świeże nasiona', id: 50 },
                { name: 'Groch na zielone nasiona', id: 51 },
                { name: 'Groch zwyczajny na suche nasiona', id: 57 },
                { name: 'Groch siewny', id: 53 },
                { name: 'Groch siewny cukrowy uprawiany na strąki', id: 54 },
                { name: 'Groch siewny uprawiany na suche nasiona', id: 55 },
                { name: 'Groch siewny uprawiany na świeże nasiona', id: 56 },
                { name: 'Groch pastewny (Peluszka)', id: 52 },
                { name: 'Groch zielony cukrowy', id: 355 },
                { name: 'Groch zielony łuskowy', id: 356 }
              ],
              id: 48
            },
            {
              name: 'Konopie',
              list: [
                { name: 'Konopie', id: 67 },
                { name: 'Konopie siewne uprawiane na nasiona', id: 68 },
                { name: 'Konopie siewne uprawiane na włókno', id: 69 }
              ],
              id: 67
            },
            {
              name: 'Słonecznik',
              list: [
                { name: 'Słonecznik', id: 102 },
                { name: 'Słonecznik oleisty', id: 103 },
                { name: 'Słonecznik zwyczajny', id: 104 }
              ],
              id: 102
            },
            { name: 'Bobik', id: 32 },
            { name: 'Brukiew', id: 33 },
            { name: 'Burak pastewny', id: 36 },
            { name: 'Gryka', id: 37 },
            { name: 'Chmiel', id: 41 },
            { name: 'Esparceta siewna', id: 42 },
            { name: 'Facelia błękitna', id: 43 },
            { name: 'Kapusta pastewna', id: 60 },
            { name: 'Kminek zwyczajny', id: 61 },
            { name: 'Kolendra siewna', id: 62 },
            { name: 'Koniczyna biała', id: 63 },
            { name: 'Koniczyna czerwona', id: 64 },
            { name: 'Koniczyna krwistoczerwona (inkarnatka)', id: 65 },
            { name: 'Koniczyna perska', id: 66 },
            { name: 'Kostrzewa czerwona', id: 70 },
            { name: 'Kostrzewa łąkowa', id: 71 },
            { name: 'Len oleisty', id: 73 },
            { name: 'Len włóknisty', id: 74 },
            { name: 'Len zwyczajny', id: 75 },
            { name: 'Lnianka siewna', id: 76 },
            { name: 'Lubczyk ogrodowy', id: 77 },
            { name: 'Lucerna mieszańcowa', id: 78 },
            { name: 'Lucerna nerkowata', id: 79 },
            { name: 'Lucerna sierpowata', id: 80 },
            { name: 'Lucerna siewna', id: 81 },
            { name: 'Łubin', id: 82 },
            { name: 'Łubin biały', id: 83 },
            { name: 'Łubin wąskolistny', id: 84 },
            { name: 'Łubin żółty', id: 85 },
            { name: 'Mak', id: 86 },
            { name: 'Owies', id: 87 },
            { name: 'Proso zwyczajne', id: 88 },
            { name: 'Pszenica orkisz', id: 90 },
            { name: 'Pszenica twarda ', id: 92 },
            { name: 'Pszenżyto jare', id: 93 },
            { name: 'Pszenżyto ozime', id: 94 },
            { name: 'Rabarbar', id: 95 },
            { name: 'Rzepa', id: 96 },
            { name: 'Rzepa pastewna', id: 97 },
            { name: 'Rzepik ozimy', id: 100 },
            { name: 'Rzodkiew  ', id: 101 },
            { name: 'Soczewica jadalna', id: 105 },
            { name: 'Soja', id: 106 },
            { name: 'Sosna zwyczajna', id: 107 },
            { name: 'Topinambur', id: 108 },
            { name: 'Wyka kosmata', id: 109 },
            { name: 'Ziemniaki sadzeniaki przechowalnie', id: 111 },
            { name: 'Życica trwała', id: 112 },
            { name: 'Życica wielokwiatowa', id: 113 },
            { name: 'Żyto jare', id: 115 },
            { name: 'Żyto ozime', id: 116 },
            { name: 'Sorgo', id: 117 },
            { name: 'Gryka zwyczajna', id: 118 },
            { name: 'Ostropest plamisty', id: 119 },
            { name: 'Wyka siewna', id: 120 },
            { name: 'Rzepak ozimy przed zbiorem', id: 121 },
            { name: 'Pszenica ozima przed zbiorem', id: 122 },
            { name: 'Pszenica jara przed zbiorem', id: 123 },
            { name: 'Pszenżyto ozime przed zbiorem', id: 124 },
            { name: 'Żyto ozime przed zbiorem', id: 125 },
            { name: 'Jęczmień jary przed zbiorem', id: 126 },
            { name: 'Jęczmień ozimy przed zbiorem', id: 127 },
            { name: 'Len zwyczajny na nasiona', id: 370 },
            { name: 'Pola uprawne przed siewem', id: 373 }
          ],
          icon: 'iconInne'
        }
      ]
    },
    {
      category: 'sadownicze',
      items: [
        { name: 'jabłoń', id: 9, icon: 'iconJablon' },
        { name: 'grusza', id: 7, icon: 'iconGruszka' },
        {
          name: 'truskawka', 
          list: [
            { name: 'truskawka', id: 26 },
            { name: 'Truskawka pod osłonami', id: 27 }
          ], 
          icon: 'iconTruskawka', 
          id: 26
        },
        {
          name: 'malina',
          list: [
            { name: 'Malina', id: 14 },
            { name: 'Malina pod osłonami', id: 15 }
          ],
          icon: 'iconMalina', 
          id: 14
        },
        { name: 'śliwa', id: 25, icon: 'iconSliwa' },
        { name: 'porzeczka czarna', id: 22, icon: 'iconPorzeczka' },
        {
          name: 'pozostałe',
          box: true,
          list: [
            { name: 'Agrest', id: 1 },
            { name: 'Aronia', id: 2 },
            { name: 'Borówka wysoka (amerykańska)', id: 4 },
            { name: 'Brzoskwinia', id: 5 },
            { name: 'Czereśnia', id: 6 },
            { name: 'Grusza azjatycka', id: 8 },
            { name: 'Jabłoń płonka', id: 10 },
            { name: 'Jarząb', id: 11 },
            { name: 'Jeżyna', id: 12 },
            { name: 'Leszczyna pospolita (Orzech laskowy)', id: 13 },

            { name: 'Morela', id: 16 },
            { name: 'Nektaryna', id: 17 },
            { name: 'Orzech włoski', id: 18 },
            { name: 'Pigwa ', id: 19 },
            { name: 'Pigwowiec', id: 20 },
            { name: 'Porzeczka biała', id: 21 },
            { name: 'Porzeczka czerwona', id: 23 },
            { name: 'Poziomka', id: 24 },

            { name: 'Winorośl', id: 28 },
            { name: 'Wiśnia', id: 29 },
            { name: 'Żarnowiec pod osłonami', id: 30 },
            { name: 'Żurawina', id: 31 },
            { name: 'Banany', id: 351 },
            { name: 'Nieszpułka zwyczajna', id: 371 },
            { name: 'Miszpelnik japoński', id: 372 },
            { name: 'Owoce cytrusowe', id: 375 }
          ],
          icon: 'iconInne'
        }
      ]
    },
    {
      category: 'warzywne',
      items: [
        {
          name: 'pomidor',
          list: [
            { name: 'Pomidor', id: 198 },
            { name: 'Pomidor', id: 322 },
            { name: 'Pomidor plantacje nasienne', id: 198 },
            { name: 'Pomidor pod osłonami', id: 199 },
            { name: 'Pomidor z rozsady', id: 200 }
          ],
          icon: 'iconPomidor',
          id: 198
        },
        {
          name: 'ogórek',
          list: [
            { name: 'Ogórek', id: 184 },
            { name: 'Ogórek plantacje nasienne', id: 185 },
            { name: 'Ogórek pod osłonami', id: 186 }
          ],
          icon: 'iconOgorek',
          id: 184
        },
        {
          name: 'cebula',
          list: [
            { name: 'Cebula', id: 138 },
            { name: 'Cebula plantacje nasienne', id: 139 },
            { name: 'Cebula siedmiolatka', id: 377 },
            { name: 'Cebula z dymki', id: 38 },
            { name: 'Cebula z rozsady', id: 39 },
            { name: 'Cebula z siewu', id: 40 },
            { name: 'Szalotka', id: 225 }
          ],
          icon: 'iconCebula',
          id: 138
        },
        { name: 'marchew', id: 177, icon: 'iconMarchew' },
        {
          name: 'kapusta',
          list: [
            { name: 'brukselska', id: 164 },
            { name: 'chińska', id: 165 },
            { name: 'głowiasta', id: 166 },
            { name: 'ozdobna', id: 170 },
            { name: 'pekińska', id: 171 },
            { name: 'włoska', id: 172 }
          ],
          icon: 'iconKapusta',
          id: 164
        },
        {
          name: 'kalafior',
          list: [
            { name: 'kalafior', id: 160 },
            { name: 'Kalafior z rozsady', id: 231 }
          ],
          icon: 'iconKalafior',
          id: 160
        },
        {
          name: 'pozostałe',
          box: true,
          list: [
            {
              name: 'Arbuz',
              list: [
                { name: 'Arbuz', id: 128 },
                { name: 'Arbuz pod osłonami', id: 129 }
              ],
              id: 128
            },
            {
              name: 'Bakłażan',
              list: [
                { name: 'Bakłażan', id: 130 },
                { name: 'Bakłażan pod osłonami', id: 131 }
              ],
              id: 130
            },
            {
              name: 'Brokuł',
              list: [
                { name: 'Brokuł', id: 134 },
                { name: 'Brokuł z siewu', id: 135 },
                { name: 'Brokuł z rozsady', id: 232 }
              ],
              id: 134
            },
            {
              name: 'Cukinia',
              list: [
                { name: 'Cukinia', id: 142 },
                { name: 'Cukinia pod osłonami', id: 143 }
              ],
              id: 142
            },
            {
              name: 'Dynia',
              list: [
                { name: 'Dynia ', id: 147 },
                { name: 'Dynia oleista', id: 148 },
                { name: 'Dynia olbrzymia', id: 359 },
                { name: 'Dynia pod osłonami', id: 149 }
              ],
              id: 147
            },
            {
              name: 'Fasola',
              list: [
                { name: 'Fasola ', id: 152 },
                {
                  name: 'Fasola zwyczajna uprawiana na suche nasiona',
                  id: 154
                },
                {
                  name: 'Fasola zwyczajna uprawiana na świeże nasiona',
                  id: 155
                }
              ],
              id: 152
            },
            {
              name: 'Kabaczek',
              list: [
                { name: 'Kabaczek', id: 158 },
                { name: 'Kabaczek pod osłonami', id: 159 }
              ],
              id: 158
            },
            {
              name: 'Melon',
              list: [
                { name: 'Melon', id: 180 },
                { name: 'Melon pod osłonami', id: 181 }
              ],
              id: 180
            },
            {
              name: 'Papryka',
              list: [
                { name: 'Papryka', id: 187 },
                { name: 'Papryka pod osłonami', id: 188 },
                { name: 'Papryka w uprawie tunelowej', id: 189 }
              ],
              id: 187
            },
            {
              name: 'Patison',
              list: [
                { name: 'Patison', id: 191 },
                { name: 'Patison pod osłonami', id: 192 }
              ],
              id: 191
            },
            {
              name: 'Pietruszka',
              list: [
                { name: 'Pietruszka', id: 194 },
                { name: 'Pietruszka korzeniowa', id: 195 },
                { name: 'Pietruszka naciowa', id: 196 }
              ],
              id: 194
            },

            {
              name: 'Por',
              list: [
                { name: 'Por', id: 202 },
                { name: 'Por z rozsady', id: 203 },
                { name: 'Por z siewu', id: 204 }
              ],
              id: 202
            },
            {
              name: 'Rzodkiewka',
              list: [
                { name: 'Rzodkiewka', id: 213 },
                { name: 'Rzodkiewka pod osłonami', id: 214 }
              ],
              id: 213
            },
            {
              name: 'Sałata',
              list: [
                { name: 'Sałata', id: 215 },
                { name: 'Sałata głowiasta', id: 216 },
                { name: 'Sałata liściowa', id: 217 },
                { name: 'Sałata łodygowa', id: 218 },
                { name: 'Sałata rzymska', id: 219 },
                { name: 'Sałata pod osłonami', id: 220 },
                { name: 'Sałata uprawa szklarniowa', id: 364 }
              ],
              id: 215
            },
            {
              name: 'Seler',
              list: [
                { name: 'Seler', id: 221 },
                { name: 'Seler korzeniowy', id: 222 },
                { name: 'Seler liściowy', id: 223 },
                { name: 'Seler naciowy', id: 224 }
              ],
              id: 221
            },
            { name: 'Bazylia pospolita', id: 132 },
            { name: 'Bób', id: 133 },
            { name: 'Brukselka', id: 136 },
            { name: 'Burak ćwikłowy', id: 137 },
            { name: 'Chrzan pospolity', id: 140 },
            { name: 'Ciecierzyca', id: 141 },
            { name: 'Cykoria korzeniowa', id: 144 },
            { name: 'Cykoria sałatowa', id: 145 },
            { name: 'Czosnek pospolity', id: 146 },
            { name: 'Endywia', id: 150 },
            { name: 'Endywia pod osłonami', id: 151 },
            { name: 'Fasola szparagowa', id: 153 },
            { name: 'Jarmuż', id: 157 },
            { name: 'Kalarepa', id: 161 },
            { name: 'Kapusta', id: 162 },
            { name: 'Karczoch zwyczajny', id: 173 },
            { name: 'Kawon', id: 174 },
            { name: 'Koper', id: 175 },
            { name: 'Kukurydza cukrowa', id: 176 },
            { name: 'Marchew pastewna', id: 179 },
            { name: 'Pasternak', id: 190 },
            { name: 'Pieczarka', id: 193 },
            { name: 'Plantacje nasienne roślin warzywnych', id: 197 },
            { name: 'Pomieszczenia magazynowe', id: 201 },
            { name: 'Rajgras wyniosły', id: 205 },
            { name: 'Rośliny warzywne', id: 206 },
            { name: 'Rośliny warzywne pod osłonami', id: 207 },
            { name: 'Rośliny zielarskie', id: 208 },
            { name: 'Rukola', id: 209 },
            { name: 'Rumianek pospolity', id: 210 },
            { name: 'Rzodkiew czarna', id: 211 },
            { name: 'Rzodkiew oleista', id: 212 },
            { name: 'Szczypiorek', id: 226 },
            { name: 'Szparag', id: 227 },
            { name: 'Szpinak', id: 228 },
            { name: 'Warzywa kapustne', id: 229 },
            { name: 'Szczaw', id: 230 },
            { name: 'Rzepa biała', id: 352 },
            { name: 'Rzepa czarna', id: 353 },
            { name: 'Rzodkiew japońska', id: 354 },
            { name: 'Skorzonera', id: 357 },
            { name: 'Salsefia', id: 358 },
            { name: 'Koper włoski', id: 362 },
            { name: 'Rzeżucha', id: 363 },
            { name: 'Szpinak uprawa szklarniowa', id: 365 },
            { name: 'Endywia uprawa szklarniowa', id: 366 },
            { name: 'Rukola uprawa szklarniowa', id: 367 },
            { name: 'Pietruszka uprawa szklarniowa', id: 368 },
            { name: 'Bazyla pospolita uprawa szklarniowa', id: 369 }
          ],
          icon: 'iconInne'
        }
      ]
    },
    {
      category: 'pozostale',
      items: [
        { name: 'Anturium', id: 233 },
        { name: 'Aster', id: 234 },
        { name: 'Aster chiński', id: 235 },
        { name: 'Aster pod osłonami', id: 236 },
        { name: 'Aksamitka', id: 237 },
        { name: 'Begonia', id: 238 },
        { name: 'Begonia pod osłonami', id: 239 },
        { name: 'Begonia zimowa', id: 240 },
        { name: 'Bluszcz', id: 241 },
        { name: 'Bukszpan ', id: 242 },
        { name: 'Byliny', id: 243 },
        { name: 'Chryzantema', id: 244 },
        { name: 'Chryzantema pod osłonami', id: 245 },
        { name: 'Cis', id: 246 },
        { name: 'Cyklamen perski', id: 247 },
        { name: 'Cyneraria  ', id: 248 },
        { name: 'Cyneraria pod osłonami', id: 249 },
        { name: 'Cynia', id: 250 },
        { name: 'Cyprysik Lawsona', id: 251 },
        { name: 'Difenbachia', id: 252 },
        { name: 'Dracena', id: 253 },
        { name: 'Drzewa ozdobne', id: 254 },
        { name: 'Figowiec pod osłonami', id: 255 },
        { name: 'Fiołek afrykański pod osłonami', id: 256 },
        { name: 'Floks  ', id: 257 },
        { name: 'Floks pod osłonami', id: 258 },
        { name: 'Fuksja', id: 259 },
        { name: 'Gerbera', id: 260 },
        { name: 'Gerbera pod osłonami', id: 261 },
        { name: 'Gerbera uprawa szklarniowa', id: 262 },
        { name: 'Goździk', id: 263 },
        { name: 'Hibiskus pod osłonami', id: 264 },
        { name: 'Kocimiętka', id: 265 },
        { name: 'Krzewy ozdobne', id: 266 },
        { name: 'Lewkonia', id: 267 },
        { name: 'Łyszczec ', id: 268 },
        { name: 'Łyszczec pod osłonami', id: 269 },
        { name: 'Modrzew', id: 270 },
        { name: 'Nagietek  ', id: 271 },
        { name: 'Nagietek pod osłonami', id: 272 },
        { name: 'Niecierpek  ', id: 273 },
        { name: 'Niecierpek nowogwinejski', id: 274 },
        { name: 'Oleander pod osłonami', id: 275 },
        { name: 'Pelargonia pod osłonami', id: 276 },
        { name: 'Pelargonia', id: 277 },
        { name: 'Plantacje nasienne roślin ozdobnych', id: 278 },
        { name: 'Pola golfowe', id: 279 },
        { name: 'Rośliny ozdobne ', id: 280 },
        { name: 'Rośliny ozdobne doniczkowe', id: 281 },
        { name: 'Rośliny ozdobne na kwiat cięty', id: 282 },
        { name: 'Rośliny ozdobne pod osłonami', id: 283 },
        { name: 'Rośliny ozdobne uprawiane z nasion', id: 284 },
        { name: 'Róża', id: 285 },
        { name: 'Róża pod osłonami', id: 286 },
        { name: 'Różanecznik', id: 287 },
        { name: 'Sadzonki sosny', id: 288 },
        { name: 'Sępolia pod osłonami', id: 289 },
        { name: 'Syningia pod osłonami', id: 290 },
        { name: 'Szkółki drzew i krzewów ozdobnych', id: 291 },
        { name: 'Ślazowiec pensylwański', id: 292 },
        { name: 'Trawniki', id: 293 },
        { name: 'Tuberoza bulwiasta pod osłonami', id: 294 },
        { name: 'Tulipan', id: 295 },
        { name: 'Zatrwian tatarski', id: 296 },
        { name: 'Złocień doniczkowy', id: 297 },
        { name: 'Boiska sportowe', id: 298 },
        { name: 'Dezynsekcja pomieszczeń', id: 299 },
        { name: 'Drewno drzew iglastych', id: 300 },
        { name: 'Drewno drzew liściastych', id: 301 },
        { name: 'Drzewa leśne - plantacje nasienne', id: 302 },
        { name: 'Drzewostany liściaste', id: 303 },
        { name: 'Drzewostany sosnowe', id: 304 },
        { name: 'Kakaowiec', id: 305 },
        { name: 'Kawa arabska', id: 306 },
        { name: 'Kawowiec', id: 307 },
        { name: 'Lasy', id: 308 },
        { name: 'Lasy zabieg aparaturą lotniczą', id: 309 },
        { name: 'Lasy zabieg aparaturą naziemną', id: 310 },
        { name: 'Leśnictwo szkółki sosna', id: 311 },
        { name: 'Leśnictwo zabezpieczenie surowca', id: 312 },
        { name: 'Mięta pieprzowa', id: 313 },
        { name: 'Miskant', id: 314 },
        { name: 'Ogrody działkowe', id: 315 },
        { name: 'Pepino', id: 316 },
        { name: 'Place magazynowe', id: 317 },
        { name: 'Plantacje nasienne modrzewia', id: 318 },
        { name: 'Plantacje nasienne roślin zielarskich', id: 319 },
        { name: 'Plantacje nasienne świerka', id: 320 },
        { name: 'Poinsecja', id: 321 },
        { name: 'Silosy paszowe', id: 323 },
        { name: 'Silosy zbożowe', id: 324 },
        { name: 'Szałwia lekarska', id: 325 },
        { name: 'Szkółki drzew leśnych', id: 326 },
        { name: 'Szkółki drzew owocowych', id: 327 },
        { name: 'Szkółki drzewek iglastych', id: 328 },
        { name: 'Szkółki leśne iglastych drzew', id: 329 },
        { name: 'Szkółki leśne liściastych drzew', id: 330 },
        { name: 'Szkółki roślin ozdobnych', id: 331 },
        { name: 'Ścierniska', id: 332 },
        { name: 'Świerk', id: 333 },
        { name: 'Tereny nieużytkowane rolniczo', id: 334 },
        { name: 'Trawy nasienne', id: 335 },
        { name: 'Tymianek właściwy', id: 336 },
        { name: 'Tytoń', id: 337 },
        { name: 'Uprawy leśne', id: 338 },
        { name: 'Użytki zielone', id: 339 },
        { name: 'Wierzba energetyczna', id: 340 },
        { name: 'Wierzba purpurowa (wiklina)', id: 341 },
        { name: 'Wiklina', id: 342 },
        { name: 'Zręby sosny i świerka', id: 343 },
        { name: 'Ziarno zbóż', id: 344 },
        { name: 'Rośliny przyprawowe', id: 345 },
        { name: 'Tory kolejowe', id: 346 },
        { name: 'Ścieżki', id: 347 },
        { name: 'Głogownik Frasera', id: 348 },
        { name: 'Trzmielina japońska', id: 349 },
        { name: 'Estragon', id: 350 },
        { name: 'Lilie', id: 360 },
        { name: 'Narcyzy', id: 361 },
        { name: 'Goździki pod osłonami', id: 374 }
      ]
    }
  ]
}

export default Plants
