import React from 'react'
import { connect } from 'react-redux'
import {
  setActiveStep,
  setNotificationPopup,
  clearAllSelectedPlants
} from '../actions'
import Plants from '../data/data'
import PlantItem from './plant-item'
import PlantItemMultiple from './plant-item-multiple'
import PlantItemsList from './plant-items-list'
import PopupDialog from './dialog-popup'

class SelectPlant extends React.Component {
  nextStep(e) {
    e.preventDefault()

    if (this.props.selectedPlants.length === 0) {
      return
    }

    this.props.setActiveStep(this.props.currentStep + 1)
    this.props.nextStep()
  }

  previousStep(e) {
    e.preventDefault()

    this.props.clearAllSelectedPlants()
    this.props.setActiveStep(this.props.currentStep - 1)
    this.props.previousStep()
  }

  handleClose = () => {
    this.props.setNotificationPopup(false)
  }

  render() {
    const plantItems =
      this.props.selectedPlantType >= 0
        ? Plants.type[this.props.selectedPlantType].items
        : []

    const items = plantItems.map((element, key) =>
      !element.hasOwnProperty('box') ? (
        <PlantItem
          name={element.name}
          key={key}
          id={element.id}
          icon={element.icon}
          items={element.list}
        />
      ) : (
        <PlantItemMultiple
          name={element.name}
          key={key}
          items={element.list}
          icon={element.icon}
          title={'formę'}
        />
      )
    )

    const isOthers = this.props.selectedPlantType === 3
    return (
      <>
        <form id="stepsForm">
          {!isOthers ? items : <PlantItemsList items={plantItems} />}
        </form>
        <div className="btnsHolder">
          <div className="btnsHolder">
            <button
              className="btnBack"
              onClick={this.previousStep.bind(this)}
              title="Cofnij"
            >
              Cofnij
            </button>
            <button
              className="btnNext"
              onClick={this.nextStep.bind(this)}
              title="Następny krok"
            >
              Następny krok
            </button>
          </div>
        </div>
        <PopupDialog
          isOpen={this.props.isNotificationPopupOpen}
          message={
            'Przekroczno maksymalną liczbę dozowlonych upraw dla jednego porgramu.'
          }
          onCloaseHandler={this.handleClose}
        ></PopupDialog>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  selectedPlantType: state.program.selectedPlantType,
  isNotificationPopupOpen: state.program.isNotificationPopupOpen,
  selectedPlants: state.program.selectedPlants
})

const mapDispatchToProps = (dispatch) => ({
  setActiveStep: (stepNumber) => dispatch(setActiveStep(stepNumber)),
  setNotificationPopup: (isOpen) => dispatch(setNotificationPopup(isOpen)),
  clearAllSelectedPlants: () => dispatch(clearAllSelectedPlants())
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlant)
