import React from 'react'
import { v4 } from 'uuid'
import md5 from 'md5'
import { connect } from 'react-redux'
import { setActiveStep } from '../actions'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import ValidatedCheckbox from './valdiated-checbox'
import { withStyles } from '@material-ui/core/styles'
import {
  setName,
  setSurname,
  setEmail,
  setPhone,
  setCompanyName,
  setTaxNumber,
  setVoucher,
  setFormValid,
  setConsent,
  setProcConsent,
  setPaymentId,
  setEncryptedBodyData
} from '../actions/index'
import download from 'downloadjs'
import Checkbox from '@material-ui/core/Checkbox'

const styles = (theme) => ({
  root: {
    margin: '90px 0 0 0 ',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  longer: {
    width: '52ch !important'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    'flex-direction': 'column'
  }
})

const ProgramForm = class ProgramForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNameValid: true,
      isSurnameValid: true,
      isEmailValid: true,
      isVoucherValid: true,
      isBackdropOpen: false,
      isConsentValid: true,
      isProcConsentValid: true,
      isConsentChecked: false,
      isProcConsentChecked: false,
      isInvoiceReqChecked: false,
      isMarketableChecked: false,
      isEmailMarketableChecked: false,
      isPhoneMarketableChecked: false,
      isImmReqChecked: false
    }
    this.decryptCallbackBodyAndSendRequest()
  }

  nextStep(e) {
    e.preventDefault()
    this.props.setActiveStep(this.props.currentStep + 1)
    this.props.nextStep()
  }

  previousStep(e) {
    e.preventDefault()
    this.props.setActiveStep(this.props.currentStep - 1)
    this.props.previousStep()
  }

  onNameChange = (event) => {
    event.preventDefault()

    this.props.setName(event.target.value)
    var result = this.validateRequired(this.props.name, '')
    this.setState({ isNameValid: !result[0] })
    this.setState({ controlSign: this.props.controlSign })
  }

  onSurnameChange = (event) => {
    event.preventDefault()
    this.props.setSurname(event.target.value)
    var result = this.validateRequired(this.props.surname, '')
    this.setState({ isSurnameValid: !result[0] })
  }

  onEmailChange = (event) => {
    event.preventDefault()
    this.props.setEmail(event.target.value)
    var val = this.validateEmail(this.props.email)
    this.setState({ isEmailValid: !val[0] })
  }

  onTelephoneChange = (event) => {
    event.preventDefault()
    this.props.setPhone(event.target.value)
  }

  onTaxNumerChange = (event) => {
    this.props.setTaxNumber(event.target.value)
  }

  onVoucherChange = (event) => {
    event.preventDefault()
    this.props.setVoucher(event.target.value)
  }

  onConsentChange = (event) => {
    this.props.setConsent(event.target.checked)
    this.setState({ isConsentValid: event.target.checked })
    this.setState({ isConsentChecked: event.target.checked })
  }

  onProcConsentChange = (event) => {
    this.props.setProcConsent(event.target.checked)
    this.setState({ isProcConsentValid: event.target.checked })
    this.setState({ isProcConsentChecked: event.target.checked })
  }

  onCheckAll = (event) => {
    this.props.setConsent(event.target.checked)
    this.props.setProcConsent(event.target.checked)
    this.setState({
      isConsentChecked: event.target.checked,
      isProcConsentChecked: event.target.checked,
      isInvoiceReqChecked: event.target.checked,
      isMarketableChecked: event.target.checked,
      isEmailMarketableChecked: event.target.checked,
      isPhoneMarketableChecked: event.target.checked,
      isImmReqChecked: event.target.checked
    })
  }

  onImmReqChange = (event) => {
    this.setState({ isImmReqChecked: event.target.checked })
  }

  onMarketableChanged = (event) => {
    this.setState({ isMarketableChecked: event.target.checked })
  }

  onEmailMarketableChecked = (event) => {
    this.setState({ isEmailMarketableChecked: event.target.checked })
  }

  onPhoneMarketableChecked = (event) => {
    this.setState({ isPhoneMarketableChecked: event.target.checked })
  }

  validateRequired = (value, message) => {
    if (value === '') {
      return [true, message]
    }

    return [false, '']
  }

  validatePhone = (value) => {
    var regex = /^([0-9]{9})$/

    if (value !== null && value !== '' && !regex.test(value))
      return [true, 'Podaj numer telefomnu w jako ciąg 9 cyfr']

    return [false, '']
  }

  validateEmail = (value) => {
    if (value === '') return [true, 'Podaj email']

    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    if (!regex.test(value)) return [true, 'Podany email nie jest poprawny']

    return [false, '']
  }

  validateTaxNumber = (value) => {
    var regex = /^[0-9]{10}$/

    if (value !== null && value !== '' && !regex.test(value)) {
      return [true, 'Podany NIP nie jest poprawny']
    }

    return [false, '']
  }

  decryptCallbackBodyAndSendRequest = () => {
    if (this.props.encryptedBody === null) {
      const currentBodyUrl = getQueryVariable('body')
      this.props.setEncryptedBodyData(currentBodyUrl)

      if (currentBodyUrl) {
        const body = decrypt(currentBodyUrl)
        this.sendRequestFromDecryptedState(body)
      }
    }
  }

  sendRequestFromDecryptedState = (historicBody) => {
    return this.sendRequest(historicBody)
  }

  sendRequestFromCurrentState = () => {
    const currentState = generateProgramBodyString(this.state, this.props, undefined)
    return this.sendRequest(currentState)
  }

  sendRequest = (body) => {
    let url = `https://apps.pest-info.pl/web/pdf/generateprogram`
    this.setState({isBackdropOpen: true})
    this.props.goToStep(4)
    this.props.setActiveStep(4)
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: body
    })
      .then(this.checkStatus)
      .then(this.handleSuccess)
      .catch(this.handleErrors)
  }

  handleSuccess = (blob) => {
    download(blob, 'program.pdf')
    this.props.goToStep(5)
    this.setState({ isBackdropOpen: false })
  }

  checkStatus = (response) => {
    if (!response.ok || response.redirected) {
      throw Error(response.statusText)
    }
    return response.blob()
  }

  handleErrors = (error) => {
    console.log(error)
    this.setState({ isBackdropOpen: false })
    this.props.goToStep(6)
  }

  render() {
    const { classes } = this.props
    const paymentId = v4()
    const merchantId = '122996'
    const priceOfProgram = 2000
    const currency = 'PLN'
    const crc = 'c046dbae3320d51d'
    const controlSign = md5(
      `${paymentId}|${merchantId}|${priceOfProgram}|${currency}|${crc}`
    )

    return (
      <>
        <form
          className={classes.root}
          id="programForm"
          action="https://secure.przelewy24.pl/trnDirect"
          method="post"
        >
          <div>* - pole wymagane</div>
          <div>
            <TextField
              required
              id="name"
              label="Wpisz imię"
              variant="outlined"
              size="small"
              onChange={this.onNameChange.bind(this)}
              helperText={!this.state.isNameValid ? 'Podaj imię' : ''}
              error={!this.state.isNameValid}
              onBlur={this.onNameChange.bind(this)}
            />
            <TextField
              required
              id="surname"
              label="Wpisz nazwisko"
              variant="outlined"
              size="small"
              onChange={this.onSurnameChange.bind(this)}
              helperText={!this.state.isSurnameValid ? 'Podaj nazwisko' : ''}
              error={!this.state.isSurnameValid}
              onBlur={this.onSurnameChange.bind(this)}
            />
          </div>
          <div>
            <TextField
              required
              id="email"
              label="Wpisz email"
              variant="outlined"
              type="email"
              size="small"
              helperText={!this.state.isEmailValid ? 'Niepoprawny email' : ''}
              error={!this.state.isEmailValid}
              onBlur={this.onEmailChange.bind(this)}
              onChange={this.onEmailChange.bind(this)}
            />

            <TextField
              id="telephone"
              label="Wpisz numer telefonu"
              variant="outlined"
              size="small"
              onChange={this.onTelephoneChange.bind(this)}
              helperText={this.validatePhone(this.props.telephone)[1]}
              error={this.validatePhone(this.props.telephone)[0]}
              onBlur={this.onTelephoneChange.bind(this)}
            />
          </div>
          <div>
            <TextField
              id="namecompany"
              label="Wpisz nazwę firmy"
              variant="outlined"
              size="small"
            />
            <TextField
              id="taxNo"
              label="Wpisz NIP"
              variant="outlined"
              size="small"
              onBlur={this.onTaxNumerChange.bind(this)}
              helperText={this.validateTaxNumber(this.props.taxNumber)[1]}
              error={this.validateTaxNumber(this.props.taxNumber)[0]}
            />
          </div>
          <div>
            <TextField
              id="voucher"
              label="Podaj voucher"
              variant="outlined"
              size="small"
              onBlur={this.onVoucherChange.bind(this)}
            />
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              <ValidatedCheckbox
                error={!this.state.isConsentValid}
                helperText="*"
                onChange={this.onConsentChange}
                checked={this.state.isConsentChecked}
                required
              ></ValidatedCheckbox>
            </div>
            <div className="consent-column-l">
              {' '}
              <span>
                Oświadczam, iż zapoznałem się z treścią{' '}
                <a
                  href="https://cdn.programochrony.pl/Regulamin.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Regulaminu
                </a>{' '}
                świadczenia usług drogą elektroniczną za pośrednictwem serwisu
                www.programochrony.pl i akceptuję jego treść.{' '}
                <strong>(zgoda obowiązkowa)</strong>
              </span>
            </div>
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              <ValidatedCheckbox
                error={!this.state.isProcConsentValid}
                helperText="*"
                onChange={this.onProcConsentChange}
                checked={this.state.isProcConsentChecked}
                required
              ></ValidatedCheckbox>
            </div>
            <div className="consent-column-l">
              Wyrażam zgodę na przetwarzanie moich danych osobowych przez
              Pest-Info Michał Czyczewski w celach związanych z realizacją
              zamawianej przeze mnie usługi w serwisie www.programochrony.pl.
              Podanie danych osobowych jest dobrowolne, lecz niezbędne do
              złożenia zamówienia. Informacje na temat przetwarzania danych
              osobowych dostępne są{' '}
              <a
                href="https://cdn.programochrony.pl/RODO.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                tutaj
              </a>
              . <strong>(zgoda obowiązkowa)</strong>
            </div>
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              <Checkbox
                color="default"
                checked={this.state.isImmReqChecked}
                onChange={this.onImmReqChange.bind(this)}
              ></Checkbox>
            </div>
            <div className="consent-column-l">
              Żądam rozpoczęcia wykonywania zamawianej przeze mnie usługi przed
              upływem terminu do odstąpienia od umowy (w razie braku wyrażenia
              ww. zgody przez konsumenta, wykonywanie usługi rozpocznie się
              najwcześniej po upływie 14 dniowego terminu na odstąpienie od
              umowy. Więcej o konsekwencjach udzielenia lub braku wyrażenia ww.
              zgody przeczytać możesz w § 5 ust. 8 i § 8 ust. 4 Regulaminu)
            </div>
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              <Checkbox
                color="default"
                checked={this.state.isMarketableChecked}
                onChange={this.onMarketableChanged.bind(this)}
              ></Checkbox>
            </div>
            <div className="consent-column-l">
              Wyrażam zgodę na przetwarzanie moich danych osobowych w celach
              handlowych i marketingowych
            </div>
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              <Checkbox
                color="default"
                checked={this.state.isEmailMarketableChecked}
                onChange={this.onEmailMarketableChecked.bind(this)}
              ></Checkbox>
            </div>
            <div className="consent-column-l">
              {' '}
              Wyrażam zgodę na przekazywanie mi informacji handlowych i
              marketingowych drogą elektroniczną, w tym w formie wiadomości
              e-mail
            </div>
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              {' '}
              <Checkbox
                color="default"
                checked={this.state.isPhoneMarketableChecked}
                onChange={this.onPhoneMarketableChecked.bind(this)}
              ></Checkbox>
            </div>
            <div className="consent-column-l">
              Wyrażam zgodę na używanie telekomunikacyjnych urządzeń końcowych,
              których jestem użytkownikiem w celach marketingu bezpośredniego
            </div>
          </div>
          <div className="consent">
            <div className="consent-column-sm">
              {' '}
              <Checkbox color="default" onChange={this.onCheckAll}></Checkbox>
            </div>
            <div className="consent-column-l">
              <strong>Zaznacz wszystkie</strong>
            </div>
          </div>

          <input type="hidden" name="p24_session_id" value={`${paymentId}`} />
          <input type="hidden" name="p24_merchant_id" value={merchantId} />
          <input type="hidden" name="p24_pos_id" value={merchantId} />
          <input type="hidden" name="p24_amount" value={priceOfProgram} />
          <input type="hidden" name="p24_currency" value={currency} />
          <input type="hidden" name="p24_country" value="PL" />
          <input type="hidden" name="p24_language" value="pl" />
          <input type="hidden" name="p24_description" value="Program ochrony" />
          <input type="hidden" name="p24_wait_for_result" value="1" />
          <input type="hidden" name="p24_email" value={this.props.email} />
          <input
            type="hidden"
            name="p24_client"
            value={`${this.props.name} ${this.props.surname} ${this.props.taxNumber}`}
          />
          <input
            type="hidden"
            name="p24_url_return"
            value={`http://programochrony.pl?body=${encrypt(
              generateProgramBodyString(this.state, this.props, paymentId)
            )}`}
          />
          <input type="hidden" name="p24_api_version" value="3.2" />
          <input type="hidden" name="p24_sign" value={controlSign} />
          <div className={`btnsHolder ${this.props.voucher ? 'hidden' : ''}`}>
            <div className="btnsHolder">
              <button
                className="btnBack"
                onClick={this.previousStep.bind(this)}
                title="Cofnij"
              >
                Cofnij
              </button>
              <button
                title="Następny krok"
                type="submit"
                className="btnNext"
                id="paymentButton"
              >
                Generuj Program, zamawiam i płacę
              </button>
            </div>
          </div>
        </form>
        <div className={`btnsHolder ${!this.props.voucher ? 'hidden' : ''}`}>
          <div className="btnsHolder">
            <button
              className="btnBack"
              onClick={this.previousStep.bind(this)}
              title="Cofnij"
            >
              Cofnij
            </button>

            <button
              title="Następny krok"
              onClick={() => this.sendRequestFromCurrentState()}
              className="btnNext"
              id="voucherButton"
            >
              Generuj Program, zamawiam i płacę
            </button>
          </div>
        </div>
        <div></div>
        <Backdrop open={this.state.isBackdropOpen} className={classes.backdrop}>
          <h1 style={{ color: '#FFFFFF' }}>
            Trwa generowanie programu ochrony
          </h1>
          <div>
            <CircularProgress color="primary" />
          </div>
        </Backdrop>
      </>
    )
  }
}

const encrypt = (text) => {
  return btoa(encode_utf8(text)).replace('==', '')
}

const decrypt = (data) => {
  return atob(decode_utf8(data))
}

function encode_utf8(s) {
  return unescape(encodeURIComponent(s))
}

function decode_utf8(s) {
  return decodeURIComponent(escape(s))
}

function getQueryVariable(variable) {
  var query = window.location?.search?.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

function generateProgramBodyString(state, props, paymentId) {
  return JSON.stringify({
    plantIds: props.selectedPlants,
    kindIds: props.selectedPrograms.map((i) => i.id),
    name: props.name,
    surname: props.surname,
    email: props.email,
    voucher: props.voucher,
    phone: props.phone,
    companyName: props.companyName,
    taxNumber: props.taxNumber,
    RulesConsent: state.isConsentValid,
    ProcessorConsent: state.isProcConsentValid,
    MarketingConsent: state.isMarketableChecked,
    EmailMarketingConsent: state.isEmailMarketableChecked,
    PhoneMarketingConsent: state.isPhoneMarketableChecked,
    ImmediateFulfilmentConsent: state.isImmReqChecked,
    PaymentId: paymentId
  })
}

const mapStateToProps = (state) => ({
  name: state.program.name,
  surname: state.program.surname,
  email: state.program.email,
  telephone: state.program.telephone,
  companyName: state.program.companyName,
  taxNumber: state.program.taxNumber,
  voucher: state.program.voucher,
  isFormValid: state.program.isFormValid,
  selectedPlants: state.program.selectedPlants,
  selectedPrograms: state.program.selectedPrograms,
  consent: state.program.consent,
  procConsent: state.program.procConsent,
  paymentId: state.program.paymentId,
  encryptedBody: state.program.encryptedBody
})

const mapDispatchToProps = (dispatch) => ({
  setActiveStep: (stepNumber) => dispatch(setActiveStep(stepNumber)),
  setName: (name) => dispatch(setName(name)),
  setSurname: (surname) => dispatch(setSurname(surname)),
  setEmail: (email) => dispatch(setEmail(email)),
  setPhone: (phone) => dispatch(setPhone(phone)),
  setCompanyName: (companyName) => dispatch(setCompanyName(companyName)),
  setTaxNumber: (taxNumber) => dispatch(setTaxNumber(taxNumber)),
  setVoucher: (voucher) => dispatch(setVoucher(voucher)),
  setFormValid: (isFormValid) => dispatch(setFormValid(isFormValid)),
  setConsent: (consent) => dispatch(setConsent(consent)),
  setProcConsent: (consent) => dispatch(setProcConsent(consent)),
  setPaymentId: (paymentId) => dispatch(setPaymentId(paymentId)),
  setEncryptedBodyData: (data) => dispatch(setEncryptedBodyData(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProgramForm))
