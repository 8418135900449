import React from 'react'
import { Link } from 'react-router-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import HomeView from './views/home'
import Details from './views/details'
import Value from './views/value'
import Contact from './views/contact'
import Price from './views/price'

function App() {
  return (
    <>
      <Router>
        <div id="top" className="centerSection">
          <h1>
            <a id="logo" href="http://www.programochrony.pl">
              Program Ochrony Roślin
            </a>
          </h1>

          <ul id="nav">
            <li>
              <Link to="/program"> O programie</Link>
            </li>
            <li>
              <Link to="/wartosc"> Dlaczego warto?</Link>
            </li>
            {/* <li><a href="#" title="Cennik">Cennik</a></li> */}
            <li>
              <Link to="/kontakt"> Kontakt</Link>
            </li>
            <li>
              <Link to="/cennik"> Cennik</Link>
            </li>
          </ul>
        </div>
        <div id="header">
          <Route exact path="/" component={HomeView} />
          <Route exact path="/program" component={Details} />
          <Route exact path="/wartosc" component={Value} />
          <Route exact path="/kontakt" component={Contact} />
          <Route exact path="/cennik" component={Price} />
        </div>
      </Router>
    </>
  )
}

export default App
