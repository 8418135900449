import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'

class PopupDialog extends React.Component {
  handleClose = () => {
    this.props.onCloaseHandler()
  }

  render() {
    return (
      <>
        <Dialog open={this.props.isOpen} scroll={'paper'}>
          <DialogTitle id="simple-dialog-title">Uwaga!</DialogTitle>
          <DialogContent dividers={true}>{this.props.message}</DialogContent>
          <Button onClick={this.handleClose}>OK</Button>
        </Dialog>
      </>
    )
  }
}

export default PopupDialog
