import React from 'react'
import { connect } from 'react-redux'
import CheckboxListDialog from './checkbox-list-dialog'

const PlantItemMultiple = class PlantItemMultiple extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isChecked: false, isDialogOpen: false }
  }

  selectPlant(e) {
    e.preventDefault()
    this.setState({ isDialogOpen: true })
  }

  handleOnDialogClose = (checked) => {
    this.setState({ isChecked: checked, isDialogOpen: false })
  }

  render() {
    const className = this.state.isChecked
      ? 'selectedLabel iconContainer'
      : 'iconContainer'

    return (
      <>
        {' '}
        <label
          className={className}
          key={this.props.hiddenkey}
          onClick={this.selectPlant.bind(this)}
        >
          <input
            type="checkbox"
            className="check-custom"
            hidden
            checked={this.state.isChecked}
          />
          <span className="check-toggle"></span>
          <img className={this.props.icon} alt={this.props.name} />
          <span className="labelTxt">{this.props.name}</span>
        </label>
        <CheckboxListDialog
          isOpen={this.state.isDialogOpen}
          items={this.props.items}
          title={this.props.title}
          onCloseHandler={this.handleOnDialogClose}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPlants: state.program.selectedPlants
})

export default connect(mapStateToProps, null)(PlantItemMultiple)
