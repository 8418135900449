import React from 'react'
import SelectType from './select-type'
import StepWizard from 'react-step-wizard'
import VerticalNavBar from './vertical-nav-bar'
import SelectPlant from './select-plant'
import SelectProgram from './select-program'
import ProgramForm from './program-form'
import SuccessStep from './success-step'
import ErrorStep from './error-step'
import diagram from '../images/diagram2.jpeg'

const Program = class Program extends React.Component {
  render() {
    return (
      <>
        <div id="program" className="centerSection">
          <h3>Generuj swój program ochrony</h3>
          <p className="subtitle">
            Dobierz najlepszy program ochrony swoich roślin zaznaczając
            odpowiednie opcje poniżej
          </p>
          <p className="subtitle">
            {' '}
            Jedno generowanie pozwala na wybór tylko jednej uprawy
          </p>
          <div className={'diagram'}>
            <img src={diagram} />
          </div>

          <VerticalNavBar />
          <StepWizard className="program-wizard">
            <SelectType />
            <SelectPlant />
            <SelectProgram />
            <ProgramForm />
            <SuccessStep />
            <ErrorStep />
          </StepWizard>
        </div>
      </>
    )
  }
}

export default Program
