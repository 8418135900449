import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import { connect } from 'react-redux'
import {
  addPlantToProgram,
  removePlantFromProgram,
  setNotificationPopup
} from '../actions/index'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  root: {
    width: '100%',
    '& .MuiList-padding': {
      padding: '8px'
    }
  },
  item: {
    'max-width': '420px',
    border: '1px solid #C8C8C8',
    '-webkit-border-radius': '10px',
    '-moz-border-radius': '10px',
    'border-radius': '10px',
    margin: '0 0 10px 0'
  },
  itemSelected: {
    'max-width': '420px',
    border: '1px solid #C8C8C8',
    '-webkit-border-radius': '10px',
    '-moz-border-radius': '10px',
    'border-radius': '10px',
    margin: '0 0 10px 0',
    background: 'rgb(0,0,0)',
    background:
      'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(62,60,60,1) 100%)',
    '-webkit-box-shadow': '0px 20px 30px 0px rgba(0,0,0,0.2)',
    '-moz-box-shadow': '0px 20px 30px 0px rgba(0,0,0,0.2)',
    'box-shadow': '0px 20px 30px 0px rgba(0,0,0,0.2)',
    color: '#FFFFFF'
  },
  checkbox: {
    color: 'white',
    '&$checked': {
      color: grey[300]
    }
  },
  dialogPaper: {
    maxHeight: '80vh'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const GreyCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: grey[300]
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />)

const CheckboxListDialog = class CheckboxListDialog extends React.Component {
  selectPlant(id, e) {
    e.preventDefault()

    const list = this.props.items?.filter(function (item) {
      return item.id === id
    })[0]?.list

    if (this.props.selectedPlants.indexOf(id) <= -1) {
      var multiplePlants = [89, 91, 58, 59, 98, 99]

      if (this.props.selectedPlantsCounter >= 2) {
        this.props.setNotificationPopup(true)
        return
      }

      if (
        !multiplePlants.includes(id) &&
        this.props.selectedPlantsCounter === 1
      ) {
        this.props.setNotificationPopup(true)
        return
      }
     

        if (list) {
        list.forEach((element) => {
          this.props.addPlant(element.id)
        })
        return
      }

      this.props.addPlant(id)
    } else {
      if (list) {
        console.log(list)
        list.forEach((element) => {
          this.props.removePlant(element.id)
        })
        return
      } else {
        this.props.removePlant(id)
      }
    }
  }

  handleClose = () => {
    let checked = this.props.selectedPlants.some((item) =>
      this.props.items.map((i) => i.id).includes(item)
    )
    console.log(checked)
    this.props.onCloseHandler(checked)
  }
  
  render() {
    const { classes } = this.props
    const fullWidth = true
    const maxWidth = 'xs'
    const scroll = 'paper'

    return (
      <Dialog
        open={this.props.isOpen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        scroll={scroll}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="simple-dialog-title">
          Wybierz {this.props.title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <List className={classes.root}>
            {this.props.items.sort(function(a, b) {
                                    var textA = a.name.toUpperCase();
                                    var textB = b.name.toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                  })
                              .map((item) => (
              <ListItem
                button
                classes={
                  this.props.selectedPlants.indexOf(item.id) > -1
                    ? { root: classes.itemSelected }
                    : { root: classes.item }
                }
                key={item.name}
                onClick={this.selectPlant.bind(this, item.id)}
              >
                <GreyCheckbox
                  value={item.id}
                  checked={this.props.selectedPlants.indexOf(item.id) > -1}
                />
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <Button onClick={this.handleClose}>Zapisz</Button>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPlants: state.program.selectedPlants,
  selectedPlantsCounter: state.program.selectedPlantsCounter
})

const mapDispatchToProps = (dispatch) => ({
  addPlant: (plant) => dispatch(addPlantToProgram(plant)),
  removePlant: (plant) => dispatch(removePlantFromProgram(plant)),
  setNotificationPopup: (isOpen) => dispatch(setNotificationPopup(isOpen))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CheckboxListDialog))
