import React from 'react'
import footer from '../images/footer.jpg'

export default class Contact extends React.Component {
  render() {
    return (
      <>
        <div id="contact">
          <p>
            Pest-Info
            <br />
            kontakt[at]pest-info.pl
            <br />
            797-498-226
          </p>
          <p>
            Zajrzyj na{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.agrovademecum.pl"
            >
              www.agrovademecum.pl
            </a>
          </p>
          <p>
            VOUCHER do nabycia w sklepie na{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.agrovademecum.pl"
            >
              www.agrovademecum.pl
            </a>
          </p>
          <img src={footer} />
        </div>
      </>
    )
  }
}
