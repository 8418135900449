import React from 'react'
import { setActiveStep } from '../actions'
import { connect } from 'react-redux'
import ProgramItem from './program-item'

const SelectProgram = class SelectProgram extends React.Component {
  nextStep(e) {
    e.preventDefault()

    if (this.props.selectedPrograms.length === 0) {
      return
    }

    this.props.setActiveStep(this.props.currentStep + 1)
    this.props.nextStep()
  }

  previousStep(e) {
    e.preventDefault()
    this.props.setActiveStep(this.props.currentStep - 1)
    this.props.previousStep()
  }

  render() {
    return (
      <>
        <form id="stepsForm" action="" method="post">
          <ProgramItem id={1} name="Choroby" price={50} />
          <ProgramItem id={2} name="Szkodniki" price={50} />
          <ProgramItem id={3} name="Chwasty" price={50} />
          <div className="btnsHolder">
            <div className="btnsHolder">
              <button
                className="btnBack"
                onClick={this.previousStep.bind(this)}
                title="Cofnij"
              >
                Cofnij
              </button>
              <button
                className="btnNext"
                onClick={this.nextStep.bind(this)}
                title="Następny krok"
              >
                Następny krok
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPrograms: state.program.selectedPrograms
})

const mapDispatchToProps = (dispatch) => ({
  setActiveStep: (stepNumber) => dispatch(setActiveStep(stepNumber))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectProgram)
