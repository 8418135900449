import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
const styles = {
  error: {
    color: 'red',
    fontSize: 'small'
  }
}

class ValidatedCheckbox extends React.Component {
  render() {
    const { error, helperText, onChange, checked } = this.props
    return (
      <>
        {error !== null && error && (
          <>
            <div style={styles.error}>{helperText}</div>
          </>
        )}

        <Checkbox
          required
          onChange={onChange.bind(this)}
          color="default"
          checked={checked}
        ></Checkbox>
      </>
    )
  }
}

export default ValidatedCheckbox
