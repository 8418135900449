import React from 'react'
import details1 from '../images/details1.jpeg'

class Details extends React.Component {
  render() {
    return (
      <>
        <div id={'details'}>
          <div className={'imagecontainer'}>
            <img src={details1} />
          </div>
          <div className={'textcontainer'}>
            <p>
              Programochrony.pl jest innowacyjnym narzędziem, które umożliwia w
              ciągu kilku sekund wygenerowanie kompletnego programu ochrony
              roślin rolniczych, sadowniczych i warzywnych oraz ozdobnych na
              podstawie wszystkich zarejestrowanych w Polsce środków ochrony
              roślin (ŚOR).
            </p>

            <p>
              {' '}
              Dzięki intuicyjnej budowie generatora, użytkownik może dokonać
              wyboru gatunku rośliny oraz grupy agrofagów, przeciwko którym
              stosować chcę ŚOR. Po raz pierwszy w Polsce, użytkownik otrzymuje
              aktualne na dzień wygenerowania raportu informacje dotyczące nazwy
              środka, dawki zastosowania, warunków stosowania, a przejrzysty
              układ otrzymanego programu ochrony pozwala wykorzystać zawarte w
              nim informacje, w przypadku pojawienia się potrzeby zastosowania
              chemicznej ochrony roślin w danej uprawie.
            </p>
            <p>
              W wygenerowanym spersonalizowanym programie ochrony, użytkownik
              nie będzie miał problemów z wyborem środka ochrony roślin oraz
              zastosowaniem go w odpowiednim terminie we właściwej dawce.
            </p>
            <p>
              W programie ochrony znajdują się również informacje o okresie
              karencji danego środka ochrony roślin, ca znaczenie w przypadku
              produktów, które trafiają na tzw. „świeży rynek”.
            </p>

            <p>
              <strong>
                „Ze środków ochrony roślin należy korzystać z zachowaniem
                bezpieczeństwa. Przed każdym użyciem przeczytaj informacje
                zamieszczone w etykiecie i informacje dotyczące produktu”.
                Podstawą do wykonania zabiegu są zawsze wskazania na etykiecie
                rejestracyjnej środka.
              </strong>
            </p>
          </div>
        </div>
      </>
    )
  }
}

export default Details
