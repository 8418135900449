import React from 'react'
import { connect } from 'react-redux'
import {
  addPlantToProgram,
  removePlantFromProgram,
  setNotificationPopup
} from '../actions'

const PlantItem = class PlantItem extends React.Component {
  selectPlant(e) {
    e.preventDefault()

    const isSelected = this.props.selectedPlants.indexOf(this.props.id) > -1
  
    // const list = this.props?.items?.filter(function (item) {
    //   return item?.id === this?.props?.id
    // })[0]?.list

    const list = this.props?.items;
  
    if (!isSelected) {
      var multiplePlants = [89, 91, 58, 59, 98, 99]

      if (this.props.selectedPlantsCounter >= 2) {
        this.props.setNotificationPopup(true)
        return
      }

      if (
        !multiplePlants.includes(this.props.id) &&
        this.props.selectedPlantsCounter === 1
      ) {
        this.props.setNotificationPopup(true)
        return
      }

      if (list) {
        list.forEach((element) => {
          this.props.addPlant(element.id)
        })
        return
      }

      this.props.addPlant(this.props.id)
    } else {
      if (list) {
        list.forEach((element) => {
          this.props.removePlant(element.id)
        })
        return
      } else {
        this.props.removePlant(this.props.id)
      }
    }
  }

  render() {
    const isSelected = this.props.selectedPlants.indexOf(this.props.id) > -1
    const className = isSelected
      ? 'selectedLabel iconContainer'
      : 'iconContainer'
    const isChecked = isSelected ? 'checked' : ''

    return (
      <>
        <label
          className={className}
          key={this.props.hiddenkey}
          onClick={this.selectPlant.bind(this)}
        >
          <input
            type="checkbox"
            className="check-custom"
            hidden
            checked={isChecked}
          />
          <span className="check-toggle"></span>
          <img className={this.props.icon} alt={this.props.name} />
          <span className="labelTxt">{this.props.name}</span>
        </label>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPlants: state.program.selectedPlants,
  selectedPlantsCounter: state.program.selectedPlantsCounter
})

const mapDispatchToProps = (dispatch) => ({
  addPlant: (plant) => dispatch(addPlantToProgram(plant)),
  removePlant: (plant) => dispatch(removePlantFromProgram(plant)),
  setNotificationPopup: (isOpen) => dispatch(setNotificationPopup(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlantItem)
