import { connect } from 'react-redux'
import React from 'react'

const VerticalNavBar = (props) => (
  <>
    <ul className={'navbar'}>
      <li id="tabNav1" className={isActiveStep(props.activeStep, 1)}>
        <a href="#" title="Wybierz typ rośliny">
          Wybierz typ rośliny{' '}
          <span>Jakiego typu rośliny chcesz objąć programem ochrony?</span>
        </a>
      </li>
      <li id="tabNav2" className={isActiveStep(props.activeStep, 2)}>
        <a href="#" title="Wybierz roślinę">
          Wybierz roślinę{' '}
          <span>
            Jeśli rośliny, której szukasz nie ma na liście - wyszukaj jej w
            sekcji <strong>Inne</strong>
          </span>
        </a>
      </li>
      <li id="tabNav3" className={isActiveStep(props.activeStep, 3)}>
        <a href="#" title="Wybierz program">
          Wybierz program <span>Zaznacz programy, których potrzebujesz</span>
        </a>
      </li>
      <li id="tabNav4" className={isActiveStep(props.activeStep, 4)}>
        <a href="#" title="Generuj program">
          Generuj program{' '}
          <span>
            Wypełnij formularz zamówienia, zapłać i pobierz program ochrony
          </span>
        </a>
      </li>
    </ul>
  </>
)

const isActiveStep = (currentStep, stepNumber) =>
  currentStep === stepNumber ? 'active' : 'toSelect'

const mapStateToProps = (state) => ({
  activeStep: state.program.activeStep
})

export default connect(mapStateToProps, null)(VerticalNavBar)
