import React from 'react'
import appstore from '../images/appstore.svg'
import google from '../images/gplay.svg'
import phones from '../images/phones.jpg'
import Program from '../components/program'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import facebook from '../images/fb_icon.png'

export default class HomeView extends React.Component {
  render() {
    return (
      <>
        <Program />

        <div id="application">
          <div id="appDesc">
            <h3>
              Vademecum Ochrony <br />w jednym miejscu
            </h3>
            <p>
              Vademecum jest pierwszą polską mobilną wyszukiwarką środków
              ochrony roślin. Aktualizowana na bieżąco, zawierająca opis
              wszystkich zarejestrowanych na rynku polskim substancji czynnych.
            </p>
            <br />
            <div className="buttonsHolder">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/pl/app/vademecum-ochrony-ro%C5%9Blin/id1441169585"
                title="Pobierz w AppStore"
              >
                <img src={appstore} alt="Pobierz w AppStore" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=pl.agroma.vademecum&amp;hl=pl"
                title="Pobierz w Google Play"
              >
                <img src={google} alt="Pobierz w Google Play" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Vademecum-%C5%9Brodk%C3%B3w-ochrony-ro%C5%9Blin-1513732122017819"
                title="Odwiedz nas na Facebook"
              >
                <img
                  src={facebook}
                  alt="Odwiedz nas na Facebook"
                  style={{ width: 42 }}
                />
              </a>
            </div>
          </div>

          <img
            className="phonesImg"
            src={phones}
            alt="Vademecum Środkow ochrony roślin"
          />
        </div>

        <div id="footer" className="centerSection">
          <ul>
            <li>
              <a
                href="https://cdn.programochrony.pl/Regulamin.pdf"
                title="Regulamin"
                target="_blank"
              >
                Regulamin serwisu
              </a>
            </li>
            <li>
              <a
                href="https://cdn.programochrony.pl/RODO.pdf"
                title="RODO"
                target="_blank"
              >
                RODO
              </a>
            </li>
            <li>
              <a
                href="https://cdn.programochrony.pl/cookies.pdf"
                title="Pliki cookies"
                target="_blank"
              >
                Pliki cookies
              </a>
            </li>
            <li>
              <a
                href="https://cdn.programochrony.pl/metody_platnosci.pdf"
                title="Metody płatności"
                target="_blank"
              >
                Metody płatności
              </a>
            </li>
          </ul>
        </div>

        <div className="overlay" id="overlay">
          <div className="center h100perc">
            <nav className="overlay-menu"></nav>
          </div>
        </div>
        <CookieConsent
          buttonText="Akceptuje"
          cookieSecurity={false}
          cookieName={'pest-info-consent'}
          onAccept={() => {
            Cookies.set('pest-info-consent', 'true')
          }}
        >
          Serwis www.programochrony.pl uzywa plików{' '}
          <a
            href="https://cdn.programochrony.pl/cookies.pdf"
            style={{ color: '#FFFFFF' }}
          >
            cookie
          </a>
          .
        </CookieConsent>
      </>
    )
  }
}
