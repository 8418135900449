import React from 'react'

class Price extends React.Component {
  render() {
    return (
      <>
        <div id={'price'}>
          <div>
            <p>
            Koszt wygenerowania dedykowanego programu ochrony wynosi 20 złotych.<br/>
            Cena dotyczy programy dla jednej wybranej  uprawy i jest niezależna, od tego czy program zawierał będzie cały program pełnej ochrony fungicydowej, herbicydowej i insektycydowej czy też poszczególnego jej wariantu.
            </p>
          </div>
        </div>
      </>
    )
  }
}

export default Price
