export function loginUser() {
  return {
    type: 'LOGIN_USER',
    payload: { isAuthenticated: true, token: ' moje pole' }
  }
}

export const setChoice = (choiceName) => ({
  type: 'SET_CHOICE',
  payload: { selectedProgram: choiceName }
})

export const setActiveStep = (stepNumber) => ({
  type: 'SET_ACTIVE_STEP',
  payload: { activeStep: stepNumber }
})

export const setSelectedPlantType = (selectedPlantType) => ({
  type: 'SET_PLANT_TYPE',
  payload: { plantType: selectedPlantType }
})

//export const setDialogItems = (items) => ({ type: "ADD_DIALOG_ITEMS", payload: { items: items, isOpen: true } })

//export const clearDialogItems = () => ({ type: "CLEAR_DIALOG_ITEMS", payload: { items: [], isOpen: false } })

export const addPlantToProgram = (plant) => ({
  type: 'ADD_SELECTED_PLANT',
  payload: { plantToAdd: plant }
})

export const removePlantFromProgram = (plant) => ({
  type: 'REMOVE_SELECTED_PLANT',
  payload: { plantToRemove: plant }
})

export const addProgram = (program) => ({
  type: 'ADD_PROGRAM',
  payload: { programToAdd: program }
})

export const removeProgram = (program) => ({
  type: 'REMOVE_PROGRAM',
  payload: { programToRemove: program }
})

export const setNotificationPopup = (isNotificationPopupOpen) => ({
  type: 'SET_POPUP',
  payload: { isNotificationPopupOpen: isNotificationPopupOpen }
})

export const clearAllSelectedPlants = () => ({
  type: 'CLEAR_ALL_SELECTED_PLANTS'
})

export const restartProgram = () => ({ type: 'RESTART' })

//FORM ACTIONS
export const setName = (name) => ({
  type: 'SET_NAME',
  payload: { name: name }
})
export const setSurname = (surname) => ({
  type: 'SET_SURNAME',
  payload: { surname: surname }
})
export const setEmail = (email) => ({
  type: 'SET_EMAIL',
  payload: { email: email }
})
export const setPhone = (phone) => ({
  type: 'SET_PHONE',
  payload: { telephone: phone }
})
export const setCompanyName = (companyName) => ({
  type: 'SET_COMPANYNAME',
  payload: { companyName: companyName }
})
export const setTaxNumber = (taxNumber) => ({
  type: 'SET_TAXNUMBER',
  payload: { taxNumber: taxNumber }
})
export const setVoucher = (voucher) => ({
  type: 'SET_VOUCHER',
  payload: { voucher: voucher }
})
export const setFormValid = (isFormValid) => ({
  type: 'SET_FORM_VALID',
  payload: { isFormValid: isFormValid }
})
export const setConsent = (consent) => ({
  type: 'SET_CONSENT',
  payload: { consent: consent }
})
export const setProcConsent = (consent) => ({
  type: 'SET_PROC_CONSENT',
  payload: { consent: consent }
})
export const setPaymentId = (paymentId) => ({
  type: 'SET_PAYMENT_ID',
  payload: { paymentId: paymentId }
})
export const setEncryptedBodyData = (encryptedBody) => ({
  type: 'SET_ENCRYPTED_BODY',
  payload: { encryptedBody: encryptedBody }
})
