const initialState = {
  selectedPlants: [],
  selectedPrograms: [],
  activeStep: 1,
  selectedPlantType: -1,
  price: 0,
  name: null,
  surname: null,
  email: null,
  telephone: null,
  companyName: null,
  taxNumber: null,
  voucher: null,
  isFormValid: true,
  isNotificationPopupOpen: false,
  consent: false,
  procConsent: false,
  selectedPlantsCounter: 0,
  paymentId: '',
  merchantId: '',
  crc: '',
  priceOfProgram: 0,
  controlSign: '',
  encryptedBody: null
}

const calculatePlantCounter = (id) => {
  var multiplePlants = [89, 91, 58, 59, 98, 99]

  if (multiplePlants.includes(id)) return 1

  return 2
}

export default function programReducer(state = initialState, action) {
  if (action.type === 'SET_CHOICE') {
    return Object.assign({}, state, {
      selectedProgram: action.payload.selectedProgram
    })
  }

  if (action.type === 'SET_ACTIVE_STEP') {
    return Object.assign({}, state, {
      activeStep: action.payload.activeStep
    })
  }

  if (action.type === 'SET_PLANT_TYPE') {
    return Object.assign({}, state, {
      selectedPlantType: action.payload.plantType
    })
  }

  if (action.type === 'ADD_SELECTED_PLANT') {
    let selectedPlantsNew = state.selectedPlants.slice()

    if (action.payload.plantToAdd === 166)
      selectedPlantsNew = selectedPlantsNew.concat(
        action.payload.plantToAdd,
        167,
        168,
        169
      )
    else selectedPlantsNew = selectedPlantsNew.concat(action.payload.plantToAdd)

    return Object.assign({}, state, {
      selectedPlants: selectedPlantsNew,
      selectedPlantsCounter:
        state.selectedPlantsCounter +
        calculatePlantCounter(action.payload.plantToAdd)
    })
  }

  if (action.type === 'REMOVE_SELECTED_PLANT') {
    let selectedPlantsNew = state.selectedPlants.slice()
    let index = selectedPlantsNew.indexOf(action.payload.plantToRemove)

    if (action.payload.plantToRemove === 166) {
      selectedPlantsNew.splice(index, 1)
      selectedPlantsNew.splice(selectedPlantsNew.indexOf(167), 1)
      selectedPlantsNew.splice(selectedPlantsNew.indexOf(168), 1)
      selectedPlantsNew.splice(selectedPlantsNew.indexOf(169), 1)
    } else selectedPlantsNew.splice(index, 1)

    return {
      ...state,
      selectedPlants: selectedPlantsNew,
      selectedPlantsCounter:
        state.selectedPlantsCounter -
        calculatePlantCounter(action.payload.plantToRemove)
    }
  }

  if (action.type === 'CLEAR_ALL_SELECTED_PLANTS') {
    let selectedPlantsNew = state.selectedPlants.slice()

    selectedPlantsNew.splice(0, selectedPlantsNew.length)

    return {
      ...state,
      selectedPlants: selectedPlantsNew,
      selectedPlantsCounter: 0
    }
  }

  if (action.type === 'ADD_PROGRAM') {
    let selectedProgramNew = state.selectedPrograms.slice()
    selectedProgramNew = selectedProgramNew.concat(action.payload.programToAdd)

    let priceNew = selectedProgramNew.reduce((total, current) => {
      return total + current.price
    }, 0)

    return { ...state, selectedPrograms: selectedProgramNew, price: priceNew }
  }

  if (action.type === 'REMOVE_PROGRAM') {
    let selectedProgramNew = state.selectedPrograms.slice()
    let index = selectedProgramNew.indexOf(action.payload.programToRemove)
    selectedProgramNew.splice(index, 1)
    let priceNew = selectedProgramNew.reduce((total, current) => {
      return total + current.price
    }, 0)
    return { ...state, selectedPrograms: selectedProgramNew, price: priceNew }
  }

  if (action.type === 'SET_NAME') {
    return { ...state, name: action.payload.name }
  }

  if (action.type === 'SET_SURNAME') {
    return { ...state, surname: action.payload.surname }
  }

  if (action.type === 'SET_EMAIL') {
    return { ...state, email: action.payload.email }
  }

  if (action.type === 'SET_PHONE') {
    return { ...state, telephone: action.payload.telephone }
  }

  if (action.type === 'SET_COMPANYNAME') {
    return { ...state, companyName: action.payload.companyName }
  }

  if (action.type === 'SET_TAXNUMBER') {
    return { ...state, taxNumber: action.payload.taxNumber }
  }

  if (action.type === 'SET_FORM_VALID') {
    return { ...state, isFormValid: action.payload.isFormValid }
  }

  if (action.type === 'SET_VOUCHER') {
    return { ...state, voucher: action.payload.voucher }
  }

  if (action.type === 'SET_POPUP') {
    return {
      ...state,
      isNotificationPopupOpen: action.payload.isNotificationPopupOpen
    }
  }

  if (action.type === 'RESTART') {
    let selectedPlantsNew = state.selectedPlants.slice()

    selectedPlantsNew.splice(0, selectedPlantsNew.length)

    let selectedProgramsNew = state.selectedPrograms.slice()

    selectedProgramsNew.splice(0, selectedProgramsNew.length)

    return {
      ...state,
      selectedPlants: selectedPlantsNew,
      selectedPrograms: selectedProgramsNew,
      selectedPlantsCounter: 0
    }
  }

  if (action.type === 'SET_CONSENT') {
    return { ...state, consent: action.payload.consent }
  }

  if (action.type === 'SET_PROC_CONSENT') {
    return { ...state, procConsent: action.payload.consent }
  }

  if (action.type === 'SET_PAYMENT_ID') {
    return { ...state, paymentId: action.payload.paymentId }
  }

  if (action.type === 'SET_ENCRYPTED_BODY') {
    return { ...state, encryptedBody: action.payload.encryptedBody }
  }

  return state
}
