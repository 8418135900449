import React from 'react'
import pages2 from '../images/pages4.JPG'
import pages3 from '../images/pages3.JPG'
import pages4 from '../images/pages1.JPG'

export default class Value extends React.Component {
  render() {
    return (
      <>
        <div id="value">
          <div className="header">
            <img src={pages4} />
            <img src={pages2} />
            <img src={pages3} />
          </div>
          <div className="content">
            <p>
              Programochrony.pl jest jedynym w Polsce tego typu narzędziem
              umożliwiającym wygenerowanie kompletnego, aktualnego programu
              ochrony jednego z ok. 370 gatunków roślin uprawnych.
            </p>
            <p>
              <strong>
                Zawartość programu ochrony zależna jest od aktualnego stanu
                środków zarejestrowanych w danej uprawie na dzień generowania. W
                przypadku upraw o niewielkim znaczeniu gospodarczym program
                ochrony może zawierać tylko kilka zarejestrowanych środków
                ochrony roślin.
              </strong>
            </p>
            <p>
              <h4>Co zyskujesz?</h4>
              <br />
              <ul>
                <li>
                  spersonalizowany plik PDF zawierający kompletny program
                  ochrony.
                </li>
                <li>
                  dostęp do najnowszej bazy danych środków ochrony roślin
                  zarejestrowanych w Polsce.
                </li>
                <li>
                  czas, który udało się zaoszczędzić na wyszukanie niezbędnych
                  informacji w innych dostępnych źródłach.
                </li>
                <li>
                  możliwość generowania plików na dowolnym urządzeniu
                  posiadającym dostęp do internetu.
                </li>
                <li>
                  cenne informacje dotyczące aktualnie zarejestrowanych środków
                  ochrony roślin w Polsce.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </>
    )
  }
}
