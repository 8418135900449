const initialState = { isAuthenticated: false, token: 'unknown token' }

export default function authReducer(state = initialState, action) {
  if (action.type === 'LOGIN_USER') {
    return Object.assign({}, state, {
      isAuthenticated: action.payload.isAuthenticated,
      token: action.payload.token
    })
  }

  return state
}
