import React from 'react'
import { connect } from 'react-redux'
import { addProgram, removeProgram } from '../actions'

const ProgramItem = class ProgramItem extends React.Component {
  selectProgram(e) {
    e.preventDefault()

    const isSelected =
      this.props.selectedPrograms.map((i) => i.id).indexOf(this.props.id) > -1

    if (!isSelected) {
      this.props.addProgram({ id: this.props.id, price: this.props.price })
    } else {
      this.props.removeProgram({ id: this.props.id, price: this.props.price })
    }
  }

  render() {
    const isSelected =
      this.props.selectedPrograms.map((i) => i.id).indexOf(this.props.id) > -1
    const className = isSelected ? 'selectedLabel' : ''
    const isChecked = isSelected ? 'checked' : ''

    return (
      <>
        {' '}
        <label
          className={className}
          key={this.props.hiddenkey}
          onClick={this.selectProgram.bind(this)}
        >
          <input
            type="checkbox"
            className="check-custom"
            hidden
            checked={isChecked}
          />
          <span className="check-toggle"></span>
          <span className="labelTxt">{this.props.name}</span>
        </label>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPrograms: state.program.selectedPrograms
})

const mapDispatchToProps = (dispatch) => ({
  addProgram: (program) => dispatch(addProgram(program)),
  removeProgram: (program) => dispatch(removeProgram(program))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramItem)
