import React from 'react'

class ErrorStep extends React.Component {
  startOver = (e) => {
    e.preventDefault()
    this.props.goToStep(1)
  }

  render() {
    return (
      <>
        <form id="stepsForm">
          <div>Bład! Przepraszamy, spróbuj ponownie!</div>
          <div className="btnsHolder">
            <div className="btnsHolder">
              <button
                className="btnNext"
                onClick={this.startOver.bind(this)}
                title="Następny program"
              >
                Sprobuję jeszcze raz
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default ErrorStep
