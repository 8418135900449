import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import { FixedSizeList } from 'react-window'
import {
  addPlantToProgram,
  removePlantFromProgram,
  setNotificationPopup
} from '../actions/index'
import { grey } from '@material-ui/core/colors'

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  item: {
    'max-width': '420px',
    border: '1px solid #C8C8C8',
    '-webkit-border-radius': '10px',
    '-moz-border-radius': '10px',
    'border-radius': '10px',
    margin: '0px 0px 0px 0px',
    padding: '0',
    '& .MuiListItem-root': {
      padding: '0'
    }
  },
  itemSelected: {
    'max-width': '420px',
    border: '1px solid #C8C8C8',
    '-webkit-border-radius': '10px',
    '-moz-border-radius': '10px',
    'border-radius': '10px',
    margin: '0',
    padding: '0',
    background: 'rgb(0,0,0)',
    background:
      'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(62,60,60,1) 100%)',
    '-webkit-box-shadow': '0px 10px 20px 0px rgba(0,0,0,0.2)',
    '-moz-box-shadow': '0px 10px 20px 0px rgba(0,0,0,0.2)',
    'box-shadow': '0px 10px 20px 0px rgba(0,0,0,0.2)',
    color: '#FFFFFF',
    '& .MuiListItem-root': {
      padding: '0'
    }
  },
  checkbox: {
    color: 'gray',
    '&$checked': {
      color: grey[300]
    }
  }
})

class PlantItemsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { items: this.props.items }
  }

  onSearchBoxFocus = (event) => {
    event.target.value = ''
  }

  onSearchBoxBlur = (event) => {
    if (event.target.value === '') event.target.value = 'Wyszukaj'
  }

  onSearchBoxChange = (event) => {
    this.setState({
      items: this.props.items.filter((item) =>
        item.name.startsWith(event.target.value)
      )
    })
  }

  selectPlant = (id, event) => {
    event.preventDefault()
    const list = this.props?.items?.filter(function (item) {
      return item.id === this?.props?.id
    })[0]?.list

    if (this.props.selectedPlants.indexOf(id) <= -1) {
      var multiplePlants = [89, 91, 58, 59, 98, 99]

      if (this.props.selectedPlantsCounter >= 2) {
        this.props.setNotificationPopup(true)
        return
      }

      if (
        !multiplePlants.includes(id) &&
        this.props.selectedPlantsCounter === 1
      ) {
        this.props.setNotificationPopup(true)
        return
      }

      if (list) {
        list.forEach((element) => {
          this.props.addPlant(element.id)
        })
        return
      }

      this.props.addPlant(id)
    } else {
      if (list) {
        list.forEach((element) => {
          this.props.removePlant(element.id)
        })
        return
      } else {
        this.props.removePlant(id)
      }
    }
  }

  renderRow = (props) => {
    const { index, style } = props
    const { classes } = this.props
    const id = this.state.items[index].id
    const name = this.state.items[index].name

    return (
      <div style={style}>
        <ListItem
          button
          classes={
            this.props.selectedPlants.indexOf(id) > -1
              ? { root: classes.itemSelected }
              : { root: classes.item }
          }
          key={index}
          onClick={this.selectPlant.bind(this, id)}
        >
          <Checkbox
            classes={{ root: classes.checkbox }}
            color="default"
            value={id}
            checked={this.props.selectedPlants.indexOf(id) > -1}
          />
          <ListItemText primary={name} />
        </ListItem>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className={'searchBar'}>
          <TextField
            id="searchBox"
            defaultValue="Wyszukaj"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              )
            }}
            onChange={this.onSearchBoxChange.bind(this)}
            onBlur={this.onSearchBoxBlur.bind(this)}
          />
          <br />
          <FixedSizeList
            height={200}
            width={500}
            itemSize={46}
            itemCount={this.state.items.length}
            className={'virtualList'}
            style={{ 'margin-top': '50px', 'margin-left': '90px' }}
          >
            {this.renderRow}
          </FixedSizeList>
        </div>
      </>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  addPlant: (plant) => dispatch(addPlantToProgram(plant)),
  removePlant: (plant) => dispatch(removePlantFromProgram(plant)),
  setNotificationPopup: (isOpen) => dispatch(setNotificationPopup(isOpen))
})

const mapStateToProps = (state) => ({
  selectedPlants: state.program.selectedPlants,
  selectedPlantsCounter: state.program.selectedPlantsCounter
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlantItemsList))
